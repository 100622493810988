import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { checkWebPSupport } from "supports-webp-sync";
import { useIsMobile } from "../../hooks";
import { SUPPORTED_REGIONS } from "../../../shared/enums";

import heroImage1 from "assets/images/landingAir/hero/hero-refresh-img-01.jpg";
import heroImageWebp1 from "assets/images/landingAir/hero/hero-refresh-img-01.jpg?as=webp";
import heroImageSm1 from "assets/images/landingAir/hero/hero-refresh-img-sm-01.jpg";
import heroImageWebpSm1 from "assets/images/landingAir/hero/hero-refresh-img-sm-01.jpg?as=webp";

const data = [
  {
    className: "",
    content: {
      leadText: "Now in 2 sizes",
      title: (
        <>
          Honey, we <br />
          shrunk the Mila
        </>
      ),
      description:
        "Introducing Mila Air Mini: Big power for your smaller spaces—so no room gets left behind.",
    },
    img: {
      desktop: heroImage1,
      webp: heroImageWebp1,
      sm: heroImageSm1,
      webpSm: heroImageWebpSm1,
    },

    button: {
      className: "button button--black",
      text: "Buy Now",
      link: "https://airmini.milacares.com/",
    },
    offerTitle: null,
    badge: {
      className: null,
      text: {
        /*
        US: (
          <>
            <span>Black Friday</span>
            Launch Exclusive <br />
            <strong>$169</strong>
          </>
        ),
        CA: (
          <>
            <span>Black Friday</span>
            Launch Exclusive <br />
            <strong>$240</strong>
          </>
        ),
        */
      },
    },
  },
  {
    className: "hero-carousel__item--black-friday",
    content: {
      leadText: "stock up on clean air",
      title: <>Our biggest sale of the year 💰</>,
      description:
        "A sale so good we couldn’t keep it to just one day.  Save up to 43% on Mila Air — the OG cleaning powerhouse that packs a boatload of performance for larger rooms.",
    },
    img: null,

    button: {
      className: "button button--white",
      text: "Save Now",
      link: "https://shop.milacares.com/",
    },
    offerTitle: (
      <>
        black <br />
        friday <br />
        sale
      </>
    ),
    badge: {
      className: "badge--alt",
      text: (
        <>
          Save up to
          <strong>43%</strong>
        </>
      ),
    },
  },
];

/**
 * @returns {JSX.Element}
 * @constructor
 */

const HeroAirSlider = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;

  const isMobile = useIsMobile(769);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [progress, setProgress] = useState(-100);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isWebPSupported = checkWebPSupport();

  const handleBeforeChange = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    if (!isSliderInitialized) return;

    setProgress(-100);

    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 0 ? -100 : prev + 1));
    }, 60);
    return () => clearInterval(interval);
  }, [isSliderInitialized, currentSlide]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    centerMode: true,
    centerPadding: "35px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          // centerPadding: "50px",
          // variableWidth: true,
          centerMode: false,
        },
      },
    ],
    onInit: () => {
      setIsSliderInitialized(true);
    },
    beforeChange: (_, nextIdx) => {
      handleBeforeChange(nextIdx);
    },
  };

  return (
    <section className="hero-carousel" style={{ "--progress": `${progress}%` }}>
      <div className="hero-carousel__container">
        <Slider className="hero-carousel__slick" {...settings}>
          {data.map((slide, index) => (
            <div
              className={`hero-carousel__item ${slide.className}`}
              key={slide.content.leadText}
            >
              {slide.img && (
                <>
                  <div
                    className="hero-carousel__item-bg"
                    style={{
                      backgroundImage: `url(${
                        isWebPSupported
                          ? isMobile
                            ? data[0].img.webpSm
                            : data[0].img.webp
                          : isMobile
                          ? data[0].img.sm
                          : data[0].img.desktop
                      })`,
                    }}
                  ></div>
                  {
                  /*
                  <div className="hero-carousel__badge">
                    {slide.badge.text[regional]
                      ? slide.badge.text[regional]
                      : slide.badge.text[SUPPORTED_REGIONS.US]}
                  </div>
                  */
                  }
                </>
              )}
              {slide.offerTitle && (
                <div className="hero-carousel__offer-card">
                  <strong className="hero-carousel__offer-title">
                    {slide.offerTitle}
                  </strong>
                  <div className="hero-carousel__badge">{slide.badge.text}</div>
                </div>
              )}
              <div className="hero-carousel__item-content">
                <div className="hero-carousel__item-content-inner">
                  <strong className="hero-carousel__leadtext">
                    {slide.content.leadText}
                  </strong>
                  <h2 className="hero-carousel__title">
                    {slide.content.title}
                  </h2>
                  <div className="hero-carousel__description">
                    <p>{slide.content.description}</p>
                  </div>
                  <a
                    href={slide.button.link}
                    className={`button ${slide.button.className}`}
                  >
                    {slide.button.text}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default HeroAirSlider;
